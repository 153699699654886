.home-static {
  height: 375px;
  position: static;
}

iframe.social-li {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.home-relative {
  height: 375px;
  position: relative;
  top: 41px;
  right: 7%;
  bottom: auto;
  z-index: 1;
  padding-right: 20px;
}

img.img-half {
  width: 50%;
}

.half.marg-left {
  margin-left: 20px;
}

div.flex.center-h.marg-top {
  align-items: center;
}

.center.argesto.lh {
  height: 50px;
}

.quote-link {
  color: white;
  text-decoration: underline;
  text-decoration-thickness: 1px;
}

.quote-link.no-dec {
  text-decoration: none;
}

.quote-link.black {
  color: #333;
  margin-top: 20px;
  width: 100%;
  padding-left: 32%;
}

.content .half.red-bg {
  background-color: #385956;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 400px;
  justify-content: center;
}

.kanzlei-team {
  flex-wrap: nowrap;
}

p.break.pod {
  height: auto !important;
}

p.break.mob {
  display: none;
}

p.break.full {
  word-break: normal;
  text-align: center;
  font-size: 22px;
  line-height: 1.5;
}

.anwalt-de-home {
  display: flex;
  justify-content: center;
}

h2.argesto.sub.center {
  text-align: center;
  margin-bottom: 30px;
  font-size: 48px;
}

div.half.red-bg h4, div.half.red-bg .inline-block {
  padding: 0 20px;
}

div.half.red-bg h4 {
  font-family: "Argesto Headline";
  font-size: 32px;
}

.half.about-img-2 {
  background-image: url("../../src/assets/office_3.jpeg");
  background-position: 60% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
}

h3.white,
p.white {
  color: white;
  padding-right: 10px;
}

.quote-link:hover {
  text-decoration: none;
  color: white;
}

.padding-1.marg-b-2 {
  margin-bottom: 30px;
}

.hero.home {
  background-image: url("../assets/home_hero.jpg");
  background-position: 0px;
}

.hero.home.formulare {
  background-image: url("../assets//formulare.jpeg");
  background-position: 0px;
}

.hero.home.familienrecht {
  background-image: url("../assets/Heaer\ Familienrecht-min.jpg");
  background-position: 0px 0px;
}

.hero.home.migrationsrecht {
  background-image: url("../assets/migrationsrecht_2.jpg");
  background-position: 0px 0px;
}

.hero.home.anwalt-notdienst {
  background-image: url("../assets/Header\ Pflichtanwalt-min.jpg");
  background-position: 0px 0px;
}

.body.padding-1.justify-text.marg-bot {
  margin-bottom: 30px;
}

.half p.padding-1 {
  padding: 0 10px;
}

.half p.padding-2 {
  margin-bottom: 30px;
}

p.eye-catcher {
  font-size: 32px;
  font-family: "Argesto Headline";
}

.inline-block.padding-1 {
  padding-left: 10px;
}

.inline-block.padding-2 {
  margin-left: -10px;
}

.default.padding-4 {
  margin-left: 30px;
}

.body.padding-3 {
  margin-right: 30px;
  margin-top: -8px;
}

.body.padding-3.but {
  margin-bottom: 30px;
}

.body.padding-4 {
  margin-left: 30px;
  margin-top: -8px;
  margin-bottom: 30px;
}

.slider-nav-backdrop {
  height: 50px;
  width: 50px;
  background-color: white;
  border-radius: 50px;
  text-align: center;
  position: relative;
  left: calc(50vw - 65px);
  top: 175px;
}

.inline-block {
  display: inline-block;
  width: 50%;
}

.section.combined .inline-block {
  margin-top: 50px;
}

.inline-block.full {
  display: inline-block;
  width: 100%;
}

.slider-nav-backdrop:hover {
  cursor: pointer;
}

.news-nav-next {
  font-size: 40px;
  font-weight: bolder;
  color: #385956;
}

.section.full.home.no-bg {
  background-image: none;
  background-color: #385956;
  height: 500px;
}

.section.full.home {
  flex-direction: column;
  max-width: 100%;
  height: 450px;
  background-image: linear-gradient(180deg,
      rgba(56, 89, 86, 0.6),
      rgba(56, 89, 86, 0.6)),
    url("../assets/home_bg_1.jpg");
  background-size: auto, cover;
  background-position: 0 0, 50% 27%;
  background-repeat: repeat, no-repeat;
}

@media only screen and (max-width: 1100px) {
  .home-relative {
    display: none;
  }

  .home-static {
    max-width: 100%;
  }
}

.half.about-img-2.kanzlei {
  background-image: none;
}

.half.about-img-2.kanzlei.one {
  background-image: url("../assets/slider_1.jpeg");
}

.half.about-img-2.kanzlei.two {
  background-image: url("../assets/slider_2.jpeg");;
}

.half.about-img-2.kanzlei.three {
  background-image: url("../assets/slider_3.jpeg");;
}

.half.about-img-2.kanzlei.five {
  background-image: url("../assets/slider_5.jpeg");;
}

.half.about-img-2.kanzlei.seven {
  background-image: url("../assets/slider_7.jpeg");;
}

.half.about-img-2.kanzlei.nine {
  background-image: url("../assets/slider_9.jpeg");;
}

.half.about-img-2.kanzlei.eleven {
  background-image: url("../assets/slider_11.jpeg");;
}

.half.about-img-2.kanzlei.twelve {
  background-image: url("../assets/team.jpg");;
}

.section.kanzlei.full.extra-margin {
  margin-top: 60px;
  margin-bottom: 20px;
}

.no-bot {
  margin-bottom: 0;
}

.contact-mrg {
  margin-bottom: 40px;
}

p.body.form-intro {
  margin-bottom: 20px;
  padding: 0 1%;
}

@media only screen and (max-width: 767px) {
  .w-image.half {
    display: none;
  }

  p.body.padding-4.marg-b-2.justify-text.tzu {
    margin-bottom: 0;
  }

  p.body.padding-4.justify-text.tzu {
    margin-bottom: 0;
  }

  div p.eye-catcher {
    font-size: 26px;
    font-family: "Argesto Headline";
  }

  p.body.padding-3 {
    margin-right: 0;
  }

  a.default.padding-4 {
    margin-left: 0px;
  }

  p.body.padding-4 {
    margin-left: 0px;
    margin-top: 0x;
    margin-bottom: 30px;
  }

  div.slider-nav-backdrop {
    left: calc(100vw - 65px);
    top: 125px;
  }

  div.two-thirds input.i-half {
    width: 98%;
  }

  .section.flex.center-h.no-bot.special {
    margin-top: -70px;
    margin-bottom: -20px;
  }

  div.center-h.contact-mrg {
    margin-bottom: 50px;
  }

  div.flex.center-h.marg-top img {
    width: auto;
  }

  .inline-block.less-bot {
    margin-bottom: 0;
  }

  div p.break.full {
    font-size: 16px;
  }

  .half.about-img-2.kanzlei {
    margin-bottom: 0;
  }

  .content .half.red-bg, .half.about-img-2 {
    height: 300px;
  }

  .content .half.red-bg p{
    margin: 5px 0;
    height: 60px;
  }

  .content .half.red-bg .inline-block{
    align-items: baseline;
  }

  p.body.padding-1 {
    padding-left: 0px;
  }

  div.inline-block.padding-1 {
    padding-left: 0px;
  }

  span.bg-quote {
    font-size: 20px;
  }


}

@media only screen and (max-width: 960px) {
  div.hero.home.familienrecht {
    background-position: -431px 100px;
  }

  div.hero.home.anwalt-notdienst {
    background-position: -150px 0px;
  }
}

@media only screen and (max-width: 1200px) {
  div.section.full.home.no-bg {
    height: 300px;
  }

  p.break.mob {
    display: block;
  }

  p.break.desk {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .inline-block {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
  }

  h3.argesto.sp-1 {
    margin-bottom: 10px;
  }

  a.m-top-2 {
    margin-top: 10px;
  }

  .bottom-m-2.home {
    margin-bottom: 0px;
    margin-top: 0;
  }

  div.inline-block.padding-2 {
    margin-left: 0;
  }
}