@font-face {
  font-family: "Lato Regular";
  src: url("./fonts/Lato/Lato-Regular.ttf");
}

@font-face {
  font-family: "Lato Light";
  src: url("./fonts/Lato/Lato-Light.ttf");
}

@font-face {
  font-family: "Lato Light Italic";
  src: url("./fonts/Lato/Lato-LightItalic.ttf");
}

@font-face {
  font-family: "Argesto Headline";
  src: url("./fonts/Argesta/ArgestaDisplay-Regular.otf");
}

.App div.content.no-scroll .section,
.App div.content.no-scroll .hero,
.App div.content.no-scroll #formular {
  display: none;
  height: 0;
}

@media only screen and (min-width: 768px) and (max-width: 960px) {
  .section.first.blog {
    margin-top: 0;
  }

  div.section.first.imp {
    margin-top: 0;
  }

  div.hero.less {
    height: 80vh;
  }

  .section.first.blog .heading1-cont {
    margin-top: 100px;
  }

  div.section.first.imp .heading1-cont {
    margin-top: 100px;
  }
}

#anwalt-notdienst-intro {
  margin-bottom: 0;
}

p.body.justify-text,
span.bg-quote.justify-text {
  text-align: justify;
}

@media only screen and (max-width: 767px) {
  .news-container {
    max-height: calc(70vh - 78px);
  }

  div p.body,
  div ul.body,
  div ol.body {
    font-size: 18px;
    line-height: 32px;
  }

  div.half.red-bg.kanzlei {
    margin-bottom: 0;
  }

  p.body.justify-text {
    text-align: left;
  }

  span.bg-quote.justify-text {
    text-align: center;
  }

  h3.sp-1 {
    margin-bottom: 0;
  }

  div.third-container.third.glossar {
    background-color: #fff;
    position: absolute;
    left: 0px;
    top: 0px;
    transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
    transform: translate3d(-100vw, 0, 0);
    z-index: 10;
    overflow: scroll;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 0;
    height: 0px;
    padding-right: 0;
    padding-top: 20px;
    padding-bottom: 30px;
    margin-top: 95px;
  }

  div.third-container.third.glossar.open {
    transform: translate3d(0vw, 0, 0);
    transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
    width: 100vw;
    height: auto;
  }

  .two-thirds #toggle-filter {
    display: flex;
  }

  div.footer-third.leftmost {
    display: none;
  }

  div.footer-third.right-most {
    /* display: none; */
  }

  div.half.flex.center-v.center-h.quest {
    margin-bottom: 0;
  }

  .half a.default.padding-1.no-left {
    margin-left: 0;
  }

  div.section.first.blog {
    margin-top: 0;
  }

  div.section.first.imp {
    margin-top: 0;
  }

  div.section.first.imp .heading1-cont {
    margin-top: 100px;
  }

  div.hero-content-r.flex.less {
    height: calc(80vh - 150px);
  }

  div.hero-content-r.flex.normal {
    height: calc(80vh - 100px);
  }

  div.section.first {
    margin-top: 0;
  }

  div.section.first.blog .heading1-cont {
    margin-top: 100px;
  }

  div.content.blog h1.argesto {
    font-size: 18px;
  }


  div.hero,
  div.hero.less {
    height: 80vh;
  }

  div.hero h2,
  div.hero .hero-desc {
    text-align: center;
  }

  div.half.anwalt img {
    padding-left: 0;
  }

  .section .heading1-cont h2.argesto {
    font-size: 32px;
  }

  div h2.argesto.sub.center {
    font-size: 32px;
  }

  .section.flex div.half {
    padding: 0;
  }

  .hero.home .hero-content {
    top: calc(100px);
  }

  a.glossar-opener {
    display: block;
  }

  .content h1 {
    font-size: 32px;
  }

  .medium .heading1-cont h2 {
    font-size: 36px;
  }

  .content .hero-content .hero-heading {
    font-size: 36px;
  }

  .content .hero-content .hero-desc {
    font-size: 16px;
  }

  .content .section {
    margin-bottom: 20px;
  }

  div.section.mob {
    display: block;
  }

  .section .half,
  .section .third {
    width: 100%;
    margin-bottom: 20px;
  }

  .section div.half {
    padding: 0 20px;
  }

  .section .third-container.third:not(.form, .glossar) {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .section .third-container.third.form {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .third-container img:not(.form) {
    width: 300px;
  }

  .form img.img-full {
    width: 100%;
  }

  .section .third.third-container {
    margin-bottom: 40px;
  }

  @media only screen and (max-width: 960px) {
    .hero-content h2.hero-header.argesto {
      font-size: 24px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  span.bg-quote {
    font-size: 24px;
  }
}

@media only screen and (max-width: 960px) {
  .hero-content h2.hero-header {
    font-size: 36px;
  }
}

@media only screen and (max-width: 560px) {
  .section .heading1-cont h2.argesto {
    font-size: 26px;
  }

  div h2.argesto.sub.center {
    font-size: 26px;
  }

  p.bottom-m-2.home {
    text-align: center;
  }
}

@media only screen and (min-width: 768px) and (max-width: 960px) {
  div.hero-content-r.flex.less {
    height: calc(80vh - 300px);
  }

  div.hero-content-r.flex.normal {
    height: calc(80vh - 400px);
  }
}

#toggle-filter {
  position: fixed;
  right: 10px;
  bottom: 10px;
  display: none;
  z-index: 10;
  color: white;
  background-color: #385956;
  height: 32px;
  width: 32px;
  padding: 10px;
  border-radius: 100%;
}

div.center {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

body {
  margin: 0;
  font-family: "Lato Regular";
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a span.argesto,
div.Dropdown-root.argesto {
  font-family: "Argesto Headline";
  font-size: 18px;
}

h1.argesto,
h2.argesto,
h3.argesto,
h4.argesto {
  font-family: "Argesto Headline";
}

h3.argesto {
  margin-bottom: 5px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.flex.center-h {
  justify-content: center;
}

.flex.center-v {
  align-items: center;
}

.flex.row {
  flex-direction: row;
}

div.leistung {
  max-width: 1200px;
}

.leistung a.news-cont {
  width: 100%;
}

.leistung a.news-cont img {
  width: 256px;
}

.flex.col {
  flex-direction: column;
}

.flex.spb {
  justify-content: space-between;
}

.flex.spa {
  justify-content: space-around;
}

.flex.spa {
  justify-content: space-evenly;
}

.flex.left {
  justify-content: left;
}

.flex.right {
  justify-content: right;
}

h3.sp-1 {
  margin-bottom: 30px;
}

.img-full {
  width: 100%;
}

.third-container {
  padding: 10px;
}

.third-container.third {
  width: 31.33333%;
  padding: 0 1%;
}

.section {
  padding: 20px;
  margin-bottom: 80px;
}

.section.first-less {
  margin-bottom: 20px;
}

.section.kanzlei {
  padding: 0;
}

.section.mob {
  display: none;
}

.section.combined {
  margin-bottom: 0;
}

.section.np {
  padding: 20px;
  margin-bottom: 0px;
}

.section .medium {
  max-width: 1200px;
  width: 100%;
}

.section.full {
  max-width: 100%;
}

.full.a-widget {
  width: 100%;
}

.full.a-widget div[data-anw-widget="11217d8b91aab1"] {
  justify-content: center;
  align-items: center;
  display: flex;
}

.full.a-widget #widgetAnwaltde {
  width: 650px;
  max-width: 100%;
}

.all-thirds-container {
  width: 100%;
}

.max-w {
  width: 100%;
  justify-content: space-between;
  opacity: 0.7;
}

span.teal {
  text-align: left;
  width: 100%;
  color: #385956;
  font-family: "Argesto Headline";
  margin-top: 10px;
}

.section.full.home.familienrecht {
  height: 480px;
}

.content.blog h1.argesto {
  font-size: 32px;
}

h4 {
  margin: 20px 0 10px 0;
  font-size: 24px;
}

h4.blog-teaser {
  width: 256px;
  color: white;
}

h4.center {
  text-align: center;
}

h4.align-left,
a.news-cont.news.art h4.align-left {
  text-align: left;
  width: 100%;
}

.bg-quote {
  color: #fff;
  font-size: 36px;
  line-height: 1.3;
  font-weight: lighter;
  text-align: center;
  max-width: 1200px;
  font-family: "Lato Light";
}

a {
  text-decoration: none;
  color: #333;
}

.footer-header.argesto {
  font-family: "Argesto Headline";
  font-weight: bold;
}

a.accented {
  color: #385956;
}

a.accented:hover {
  text-decoration: underline;
}

a:hover {
  color: #385956;
  cursor: pointer;
}

.half {
  width: 50%;
}

.bottom-divider.footer {
  height: 1px;
  background-color: black;
  opacity: 0.5;
}

.glossar-letter {
  font-family: "Argesto Headline";
  font-size: 24px;
  font-weight: bold;
  color: #385956;
}

.glossar-opener {
  font-size: 24px;
  font-weight: bold;
  font-family: "Argesto Headline";
  color: #385956;
  display: none;
  text-decoration: underline;
  margin-top: 20px;
  margin-bottom: 40px;
}

.divider-glossar {
  height: 1px;
  background-color: black;
  opacity: 0.3;
  margin-top: 50px;
  margin-bottom: 50px;
}

.argesto.center-header {
  text-align: center;
  font-family: "Argesto Headline";
  font-size: 32px;
}

.argesto.footer-copyright {
  font-family: "Argesto Headline";
  color: #385956;
  font-size: 15px;
}

.center-desc {
  text-align: center;
  font-size: 20px;
  opacity: 0.7;
  margin-bottom: 60px;
}

.glossar-term {
  margin-bottom: 10px;
  display: block;
  font-size: 18px;
}

.glossar-def {
  font-size: 24px;
  color: #385956;
  font-weight: bold;
}

.glossar-desc {
  font-size: 18px;
  line-height: 1.5;
}

.glossar-term:hover {
  text-decoration: underline;
}

.third {
  width: 33.33333%;
}

.two-thirds {
  width: 66.666666%;
}

.part-40 {
  width: 40%;
}

.part-20 {
  width: 20%;
}

.part-15 {
  width: 140px;
}

li.first-li {
  margin-top: 10px;
}

.part-30 {
  width: 30%;
}

.part-60 {
  width: 60%;
}

.part-70 {
  width: 70%;
}

.quart {
  width: 25%;
}

.tenth {
  width: 10%;
}

.part-45 {
  width: 45%;
}

.part-45.news-part {
  width: auto;
}

.part-45.news-part img {
  margin-right: 10px;
}

.fifth {
  width: 20%;
}

.four-fifths {
  width: 80%;
}

.accent,
.active {
  color: #385956;
}

.m-left-1 {
  margin-left: 5px;
}

.m-right-1 {
  margin-right: 5px;
}

.m-left-2 {
  margin-left: 10px;
}

.full-width {
  width: 100%;
}

.m-right-2 {
  margin-right: 10px;
}

.m-left-3 {
  margin-left: 20px;
}

.m-right-3 {
  margin-right: 20px;
}

.m-bottom-3 {
  margin-bottom: 20px;
}

.m-top-4 {
  margin-top: 35px;
}

.m-top-2 {
  margin-top: 20px;
}

.bottom-m-2 {
  margin-bottom: 30px;
  margin-top: 0;
  line-height: 1.5;
}

.quote-span {
  color: white;
}

.quote-header {
  color: white;
  font-size: 24px;
}

nav {
  box-shadow: 0 1px 0 0 rgb(0 0 0 / 20%);
}

.section.first.blog {
  margin-top: 100px;
}

.section.first.imp {
  margin-top: 100px;
}

.heading-deco {
  width: 50px;
  height: 4px;
  margin-top: 6px;
  background-color: #385956;
}

h1 {
  font-size: 48px;
  line-height: 1.2;
  font-weight: 400;
  text-align: center;
}

.heading1-cont {
  width: 100%;
}

.heading1-cont.news-h {
  width: 100%;
  margin-left: 20px;
}

.heading1-cont.news-h h2 {
  color: white;
}

.heading1-cont.news-h div {
  background-color: white;
}

.heading1-cont h2 {
  font-size: 48px;
  line-height: 1.2;
  font-weight: 400;
}

h2.sub {
  margin-bottom: 10px;
}

span.sub-headline {
  font-size: 18px;
  opacity: 0.8;
  margin-bottom: 20px;
}

.section.more-mrg {
  margin-bottom: 80px;
}

.half.anwalt img {
  padding-left: 10px;
}

#widgetAnwaltde {
  width: 100%;
  max-width: none !important;
}

.half a.default.padding-1 {
  margin-left: 10px;
}

button.default,
a.default {
  font-family: "Lato Regular";
  font-size: 18px;
  padding: 20px 20px;
  color: white;
  text-align: center;
  text-decoration: none;
  background-color: #385956;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin-top: 20px;
}

a.default.glossar {
  width: 60px;
  font-size: 24px;
  font-family: "Argesto Headline";
}

a.default.no-margin {
  margin-top: 0px;
}

a.default.inverse {
  font-family: "Lato Regular";
  font-size: 18px;
  padding: 15px 20px;
  min-width: 100px;
  color: #385956;
  text-align: center;
  text-decoration: none;
  background-color: white;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin-top: 20px;
}

a.default:hover {
  text-decoration: underline;
}

a.default.inverse:hover {
  text-decoration: underline;
}

button.default.not-active {
  font-size: 17px;
  padding: 10px 20px;
  color: #333;
  text-align: center;
  text-decoration: none;
  background-color: gainsboro;
  border: none;
  cursor: pointer;
}

button.default.inverted {
  background-color: white;
  color: #385956;
}

.v-end {
  align-items: end;
}

.checkbox {
  margin-top: 7px;
  margin-right: 10px;
}

.checkbox-cont {
  width: 50%;
}

.content img {
  max-width: 100%;
}

.content.blog .heading1-cont img {
  max-width: 100%;
  width: 500px;
}

.hero {
  margin-top: -49px;
  width: 100%;
  height: 70vh;
  background-size: cover;
  background-repeat: no-repeat;
}

.hero.less {
  height: 50vh;
}

.red-bg-hero {
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.6;
}

.hero.ref {
  background-image: url("../src/assets/home_hero.jpg");
}

.hero-content {
  position: absolute;
  top: 180px;
  width: 100%;
}

.hero-content-r {
  max-width: 1200px;
  flex-direction: column;
  justify-content: center;
  height: calc(70vh - 200px);
  padding: 0 20px;
}

.hero-content-r.less {
  height: calc(50vh - 200px);
}

/* .hero-content {
  left: 19%;
  max-width: 60%;
  position: absolute;
  top: 45%;
} */

.news-container {
  flex-wrap: nowrap;
  max-height: calc(70vh - 118px);
}

.hero-content .hero-heading {
  color: #fff;
  font-size: 50px;
  line-height: 1.3;
  text-align: left;
  margin: 0;
  margin-bottom: 10px;
}

.hero-content .hero-desc {
  color: #fff;
  font-size: 18px;
  line-height: 1.5;
  max-width: 1200px;
}

.hero-content .hero-header {
  color: #fff;
  font-size: 36px;
  margin-top: 10px;
  margin-bottom: 0;
}

.hero-content .hero-span {
  color: #fff;
  font-size: 18px;
}


p.body,
ul.body,
ol.body {
  color: #333;
  font-size: 20px;
  line-height: 36px;
  font-weight: 400;
  text-align: left;
  margin: 0;
}

p.full {
  width: 100%;
}

p.body.center {
  text-align: center;
}

p.body.quote {
  font-style: italic;
}

body pre {
  font-family: "Lato Regular";
  color: #333;
  font-size: 17px;
  line-height: 32px;
  font-weight: 400;
  text-align: left;
  margin: 0;
}

div.full.btn {
  width: 100%;
}