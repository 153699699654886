@media only screen and (max-width: 767px) {
  .full.contact {
    justify-content: center;
  }

  a.news-cont.art {
    width: 98%;
    margin: 1% 1%;
  }

  a.news-cont.news.art.flex h4 {
    height: auto;
  }

  .body.contact {
    text-align: center;
  }

  .third,
  .two-thirds {
    width: 100%;
  }

  .two-thirds {
    margin-bottom: 50px;
  }

  .contact-cont .two-thirds input.i-half {
    width: 98%;
  }

  .two-thirds div.full {
    flex-wrap: wrap;
  }

  a.news-cont {
    width: 48%;
    margin: 0 1%;
  }

  .news h1 {
    font-size: 26px;
  }

  .po-box div.third {
    flex-direction: column;
  }

  .po-box div img {
    max-height: 150px;
    min-height: 150px;
  }

  div.po-box {
    width: 100%;
  }

  .react-pdf__Document,
  .react-pdf__Page,
  .react-pdf__Page__canvas,
  .react-pdf__Page__textContent {
    margin: 0 auto;
    width: 100% !important;
    height: auto !important;
  }
}

.body.contact {
  font-family: "Argesto Headline";
  font-size: 16px;
  color: #385956;
}

@media only screen and (min-width: 768px) and (max-width: 960px) {
  .section.first.blog {
    margin-top: 0;
  }

  .section.first.blog .heading1-cont {
    margin-top: 100px;
  }

  a.news-cont.blog {
    width: 48%;
    margin: 20px 1%;
    min-height: auto;
  }
}

@media only screen and (max-width: 1100px) {
  .po-box .third.flex {
    width: 100%;
    margin: 1% 0;
  }

  .po-box-2 a.half {
    width: 100%;
    margin: 1% 0;
  }

  a.news-cont.news.art h4 {
    height: 80px;
  }
}

@media only screen and (max-width: 600px) {
  a.news-cont.blog {
    width: 100%;
    margin-bottom: 20px;
    min-height: auto;
  }
}

@media only screen and (max-width: 960px) {
  a.news-cont.news.art h4 {
    font-size: 16px;
  }
}

.po-box-2 {
  width: 100%;
  margin-top: 20px;
}

.po-box-2 h2 {
  text-align: center;
  font-size: 32px;
}

.po-box-2 h3 {
  margin-top: 0;
  font-size: 24px;
}

.po-box img {
  max-height: 250px;
  min-height: 250px;
}

.po-box-2 .half {
  width: 44%;
  margin: 0 1% 2% 1%;
  flex-direction: column;
  display: flex;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%);
}

.po-box .third {
  width: 30.33%;
  margin: 0 1%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%);
}

.po-box .third.active {
  border: 1px solid #385956;
}

.news h1 {
  margin-bottom: 10px;
}

.news p.body.desc {
  color: gray;
  margin-bottom: 20px;
}

.news .bottom-divider {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 20px;
}

.contact-cont {
  margin-top: 40px;
  width: 100%;
}

.w-checkbox.checkbox-cont.flex.row {
  width: 100%;
  margin-top: 10px;
  flex-wrap: nowrap;
}

.w-checkbox.checkbox-cont.flex.row span {
  display: flex;
  align-items: center;
}

.two-thirds input,
.two-thirds textarea {
  font-family: "Lato Regular";
  display: block;
  height: 38px;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 1.3;
  margin-right: 2%;
  color: #333;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.news-cont {
  width: 31.333%;
  margin: 0 1%;
}

.news-cont.blog {
  min-height: 400px;
}

.news-cont.art {
  width: 48%;
  margin: 20px 1%;
}

.news-cont.art img {
  width: 100%;
}

.news-cont.news.art h4 {
  font-family: "Argesto Headline";
  text-align: center;
  height: 65px;
}

.news-cont.news.art p {
  opacity: 0.8;
}

.news-contt {
  width: 100%;
}

.news-contt.half {
  width: 50%;
}

.news-cont h4 {
  font-family: "Lato Regular";
  font-size: 18px;
  font-weight: 400;
  margin-top: 5px;
  margin-bottom: -5px;
  justify-content: center;
  align-items: center;
}

.news-cont.news h4 {
  height: 100px;
}

p.body.error-msg {
  margin-bottom: 20px;
  color: #385956;
}

p.body.success-msg {
  margin-bottom: 20px;
  color: #385956;
}

.two-thirds input.i-half {
  margin-bottom: 10px;
  width: 48%;
}

.two-thirds input.i-full {
  margin-bottom: 10px;
}

.two-thirds input.i-full,
.two-thirds textarea {
  width: 100%;
}

.two-thirds textarea {
  height: 100px;
}

.two-thirds div.full {
  width: 100%;
  display: flex;
}