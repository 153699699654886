.section.footer {
  max-width: 1200px;
  width: 100%;
}

.footer-third {
  width: 170px;
}

.footer-third a {
  text-decoration: none;
  color: #333;
  margin-bottom: 10px;
}

.footer-third a:hover {
  color: #385956;
}

.bottom-divider.footer {
  margin-bottom: 30px;
}

.footer-header {
  margin-bottom: 10px;
  color: #385956;
  font-size: 17px;
  font-weight: 700;
}

.footer-top {
  margin-top: 15px;
}

.footer-third.leftmost span {
  margin-bottom: 10px;
}

@media only screen and (max-width: 767px) {
  span.footer-header.argesto {
    display: none;
  }

  .footer-third {
    width: 42%;
    margin-bottom: 20px;
    display: flex;
    align-items: baseline;
    margin-left: 8%;
  }

  div.footer-top {
    margin-top: 0px;
  }
}