@media only screen and (max-width: 1100px) {
  .nav-lower div.Dropdown-option {
    font-size: 16px;
  }

  .nav-lower a span.argesto {
    font-size: 16px;
  }

  div.Dropdown-placeholder {
    font-size: 16px;
  }
}

@media only screen and (max-width: 960px) {
  .mob-menu .nav-info {
    margin-top: 20px;
  }
}


nav {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  top: -50px;
  right: 0;
  background-color: #fff;
  z-index: 99;
}

div.marg-bot-25 {
  margin-bottom: 20px;
}

div.marg-bot-10 {
  margin-bottom: 0px;
}

.nav-lower .Dropdown-root .Dropdown-control {
  border: none;
  padding: 8px 10px 8px 20px;
}

.nav-lower .Dropdown-root .Dropdown-arrow-wrapper {
  display: none;
}

.nav-lower .Dropdown-menu {
  width: 200%;
  border: none;
}

.nav-lower .Dropdown-option {
  font-size: 18px;
  outline: none;
}

a.marg-right-1 {
  margin-right: 10px;
}

.nav-lower {
  position: absolute;
  width: 100%;
  height: 100px;
}

.nav-lower-cont {
  max-width: 1240px;
  width: 100%;
  z-index: 99;
  background-color: white;
  box-shadow: 1px 1px 1px 1px rgb(0 0 0 / 20%);
}

.nav-lower-cont .part-40 a {
  padding: 0 10px;
}

.nav-lower .Dropdown-option:hover {
  text-decoration: underline;
  text-decoration-color: #385956;
  color: #385956;
}

.nav-lower .Dropdown-option:focus-visible {
  outline: none;
}

.nav-upper {
  background-color: #385956;
}

.nav-upper.section {
  padding: 15px;
  color: white;
  margin-bottom: 0;
}

.nav-upper-cont {
  max-width: 1200px;
  width: 100%;
}

.nav-upper .nav-info {
  margin-right: 10px;
}

.right-mrg {
  margin-right: 35px;
}

.search-input,
.search-input:focus-visible {
  border-radius: 50px 0 0 50px;
  border: 1px solid rgba(51, 51, 51, 0.2);
  padding: 9px 20px;
  width: 150;
  font-size: 14px;
  outline: none !important;
}

.search-submit {
  color: white;
  border-radius: 0 50px 50px 0;
  border: 1px solid #385956;
  padding: 9px 20px;
  font-size: 14px;
  outline: none !important;
  background-color: #385956;
}

.mob-menu form {
  margin: 40px 0;
}

.mob-menu .nav-link {
  width: 100%;
  text-align: left;
  font-size: 20px;
  text-decoration: underline;
  margin-bottom: 15px;
  font-family: "Argesto Headline";
  padding-left: 32%;
}

.nav-upper {
  padding-bottom: 0;
}

.nav-lower a {
  text-decoration: none;
  font-size: 16px;
}

.nav-lower a:hover {
  text-decoration: underline;
  text-decoration-color: #385956;
}

.hamburger-cont {
  display: none;
}

.social-links-mob {
  margin-top: 20px;
  width: 100%;
  padding-left: 32%;
}

.divider {
  width: 300px;
  height: 1px;
  margin-bottom: 20px;
  background-color: #385956;
}

.el .divider {
  width: 100%;
}

.mob-menu {
  background-color: #fff;
  position: absolute;
  margin-top: 100px;
  left: 0;
  top: 0;
  transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
  transform: translate3d(100vw, 0, 0);
  z-index: 10;
  overflow: hidden;
  width: 0;
  height: 0px;
  padding-right: 0;
  padding-top: 30px;
  display: none;
}

.nav-lower a span {
  font-size: 18px;
}

.Dropdown-placeholder {
  font-size: 18px;
}

.mob-menu.open {
  display: block;
  transform: translate3d(0, 0, 0);
  transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
  width: 100vw;
  height: calc(100vh - 80px);
}

.mob-logo {
  display: none;
}

@media only screen and (max-width: 1200px) {
  .nav-upper-cont {
    width: 100%;
  }
}

@media only screen and (max-width: 960px) {

  div.nav-lower.flex .nav-lower-cont {
    justify-content: space-between;
    padding: 0 20px;
  }

  .mob-logo {
    display: block;
  }

  .nav-upper {
    display: none;
  }

  .nav-lower .part-40,
  .nav-lower .part-15 {
    display: none;
  }

  nav .part-45 {
    width: 50%;
  }

  nav {
    top: 0;
  }

  nav .nav-upper.section {
    padding: 15px;
  }

  .hamburger-cont {
    color: #385956;
    display: block;
  }
}